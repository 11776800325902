<script setup>
import { Head } from '@inertiajs/vue3';
import DashboardLayout from '@/Layouts/DashboardLayout.vue';
import Revenue from '@/Pages/Dashboard/Partials/Revenue/Revenue.vue';
import Employees from '@/Pages/Dashboard/Partials/Employees/Employees.vue';
import Projects from '@/Pages/Dashboard/Partials/Projects/Projects.vue';
import Events from '@/Pages/Dashboard/Partials/Events/Events.vue';
import Dynamic from '@/Pages/Dashboard/Partials/Dynamic/Dynamic.vue';
import Sales from '@/Pages/Dashboard/Partials/Sales/Sales.vue';

defineProps({
    profile: Object,
    hasSales: Boolean,
})

// Reload interval for every component (10 minutes)
const interval = 10 * 60 * 1000;
</script>

<template>
    <Head title="Dashboard"/>

    <DashboardLayout :profile>
        <Revenue :profile class="col-span-2" :interval="interval"/>
        <Sales :profile class="col-span-2" :interval="interval" v-if="hasSales"/>
        <Employees v-if="!hasSales" :profile class="col-span-2" :interval="interval" :small="hasSales"/>
        <Dynamic :profile class="col-span-1" :interval="interval" v-if="!hasSales"/>
        <Events :profile class="col-span-1" :interval="interval"/>
        <Employees v-if="hasSales" :profile class="col-span-1" :interval="interval" :small="hasSales"/>
        <Projects :profile class="col-span-2" :interval="interval"/>
    </DashboardLayout>
</template>
